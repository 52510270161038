import React from "react"
import styled from "styled-components"
import { Dialog, Divider, Typography } from "@material-ui/core"
import PersonalInformationForm from "./PersonalInformationForm/PersonalInformationForm"
import { useIntl } from "react-intl"

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    width: 100%;
    margin: 0;

    .MuiPaper-root {
      background: linear-gradient(
          189deg,
          rgb(27 114 177) 0%,
          rgb(9 113 144) 26%,
          rgb(34 103 136) 27%,
          rgb(5 37 70) 100%
        ),
        radial-gradient(
          circle,
          rgba(88, 202, 245, 1) 0%,
          rgba(12, 117, 167, 1) 100%,
          rgba(0, 100, 148, 1) 100%,
          rgba(4, 13, 55, 1) 100%
        );
      color: white;
      width: 100%;
    }
  }

  .MuiDialog-paper {
    padding: 20px;
    @media only screen and (min-width: 1024px) {
      max-height: 730px;
    }
  }
`

const StyledDialogTitle = styled(Typography)`
  padding: 0 0 20px;
  font-size: 2.2rem;
  text-align: center;
  font-weight: 600;
  color: #fff;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  @media only screen and (min-width: 1024px) {
    font-size: 2.7rem;
  }
`

const ArrangeDialog = props => {
  const {
    handleClose,
    arrangeDialogOpen,
    setEmailSendOpen,
    setSendError,
  } = props

  const { formatMessage } = useIntl()

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={arrangeDialogOpen}
    >
      <StyledDialogTitle variant="h4">
        {formatMessage({ id: "Book a flight 2" }).toLocaleUpperCase()}
      </StyledDialogTitle>

      <Divider style={{ backgroundColor: "rgb(0 0 0 / 0.1)" }} />

      <PersonalInformationForm
        handleClose={handleClose}
        setEmailSendOpen={setEmailSendOpen}
        setSendError={setSendError}
      />
    </StyledDialog>
  )
}

export default ArrangeDialog
