import React from "react"
import styled from "styled-components"
import { Dialog, Divider, Typography } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"
import { useIntl } from "gatsby-plugin-intl"

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgb(2 12 27 / 90%);
  }
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.darkBlue};

    .MuiPaper-root {
      padding: 10px 0 40px;
      background: linear-gradient(
          189deg,
          rgb(27 114 177) 0%,
          rgb(9 113 144) 26%,
          rgb(34 103 136) 27%,
          rgb(5 37 70) 100%
        ),
        radial-gradient(
          circle,
          rgba(88, 202, 245, 1) 0%,
          rgba(12, 117, 167, 1) 100%,
          rgba(0, 100, 148, 1) 100%,
          rgba(4, 13, 55, 1) 100%
        );
      color: white;
      width: 100%;
    }
  }

  .MuiDialog-paper {
    margin: 0;
    padding: 20px;
    width: 100%;
  }
`

const StyledDialogTitle = styled(Typography)`
  padding: 0 0 20px;
  margin-top: 20px;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 400;
  @media only screen and (min-width: 1024px) {
    font-size: 1.8rem;
  }
`

const StyledImage = styled(Image)`
  width: 100px;
  margin: 0 auto 30px;
`
const StyledTypography = styled(Typography)`
  width: 90%;
  margin: 0 auto;
  font-weight: 300;
`

const StyledButton = styled.button`
  position: relative;
  cursor: pointer;
  margin-left: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ color }) => (color === "secondary" ? `#445CA4` : "white")};
  padding: 6px 30px !important;
  border: none;
  font-weight: 600;
  text-decoration: none;
  outline: none;

  span {
    pointer-events: none;
    transition: transform 0.15s linear;
    transform: translateX(0);
    font-weight: 600;

    color: ${({ color }) => (color === "secondary" ? `#445CA4` : "white")};
  }
  svg {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-400%, -50%);
    color: ${({ color }) => (color === "secondary" ? `#445CA4` : "white")};
    path {
      color: ${({ color }) => (color === "secondary" ? `#445CA4` : "white")};
    }
  }
  ::after {
    display: none;
  }
  &:hover span {
    transform: translateX(-12px);
  }
  &:hover svg {
    transform: translate(-300%, -50%);
    opacity: 1;
  }
`
const StyledIcon = styled(FontAwesomeIcon)`
  position: absolute;
  transform: translateX(0);
  opacity: 0;
  transition: all 0.15s linear;
`
const InformationDialog = props => {
  const { handleCloseInformationDialog, informationDialogOpen } = props
  const { formatMessage } = useIntl()
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "logo-color" }) {
        childImageSharp {
          fixed(width: 150, quality: 90) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledDialog
      onClose={handleCloseInformationDialog}
      aria-labelledby="simple-dialog-title"
      open={informationDialogOpen}
      disableBackdropClick={true}
    >
      <StyledImage fixed={data.file.childImageSharp.fixed} />
      <Divider style={{ backgroundColor: "rgb(0 0 0 / 0.1)" }} />
      <StyledDialogTitle variant="h4">
        {formatMessage({ id: "Before you book a flight" })}
      </StyledDialogTitle>
      <StyledTypography variant="subtitle2" component="p">
        {formatMessage({ id: "Before you book a flight information" })}
        <br />
        <br />
        {formatMessage({ id: "See you soon" })}
        <br />
        {formatMessage({ id: "Skywalkers team" })}
      </StyledTypography>

      <StyledButton onClick={handleCloseInformationDialog}>
        <span>Ok</span>
        <StyledIcon icon={faLongArrowAltRight} />
      </StyledButton>
    </StyledDialog>
  )
}

export default InformationDialog
