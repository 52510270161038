import React, { useRef, useState } from "react"
import styled from "styled-components"
import moment from "moment"
import "moment/locale/pl"
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import emailjs from "emailjs-com"
import CircularProgress from "@material-ui/core/CircularProgress"
import InfoIcon from "@material-ui/icons/Info"
import { useIntl } from "gatsby-plugin-intl"

const StyledGrid = styled(Grid)`
  .MuiOutlinedInput-notchedOutline {
    border-color: rgb(0 0 0 / 23%) !important;
  }

  input,
  select,
  textarea {
    font-size: 14px;
  }
  #date-picker-inline {
    font-size: 14px;
    padding-left: 10px;
    color: #fff;
    background-color: #000;
  }

  input::selection {
    background: #f7a494;
  }
  input#name:-webkit-autofill {
    background-color: #0c2a3a !important;
  }

  .MuiOutlinedInput-input {
    padding: 12px 10px;
    font-size: 14px;
    height: 1.8em;
  }

  .MuiTypography-body1 {
    font-size: 14px;
  }
`

const PersonalInformationForm = props => {
  const { handleClose, setEmailSendOpen, setSendError } = props
  const [errorTitle, setErrorTitle] = useState(false)
  const [nameValue, setNameValue] = useState("")
  const [phoneNumberValue, setPhoneNumberValue] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [cityValue, setCityValue] = useState("")
  const [voivodeshipValue, setVoivodeshipValue] = useState("")
  const [flightDate, setFlightDate] = useState(moment(new Date()))
  const [disabledDates] = useState([
    "2020-09-25",
    "2020-09-26",
    "2020-09-27",
    "2020-09-28",
  ])
  const [radioValue, setRadioValue] = useState("1-2 osób")
  const circularRef = useRef()
  const buttonTxtRef = useRef()
  const { formatMessage } = useIntl()

  const handleFlightDateChange = date => {
    setFlightDate(date)
  }
  const handleNameChange = e => {
    if (errorTitle) {
      setErrorTitle(false)
    }
    setNameValue(e.target.value)
  }
  const handlePhoneNumberChange = e => {
    setPhoneNumberValue(e.target.value)
  }
  const handleEmailChange = e => {
    setEmailValue(e.target.value)
  }
  const handleCityChange = e => {
    setCityValue(e.target.value)
  }
  const handleVoivodeshipChange = e => {
    setVoivodeshipValue(e.target.value)
  }

  const disableWeekends = date => {
    return disabledDates.filter(
      singleDate => singleDate === moment(date).format("YYYY-MM-DD")
    )[0]
  }
  const handleRadioChange = event => {
    setRadioValue(event.target.value)
  }

  const onHandleClose = e => {
    e.preventDefault()
    handleClose()
  }
  const handleSubmit = e => {
    e.preventDefault()

    if (circularRef.current) {
      circularRef.current.style.opacity = 1
      buttonTxtRef.current.style.opacity = 0

      emailjs
        .sendForm(
          process.env.GATSBY_EMAIL_JS_SERVICE,
          process.env.GATSBY_EMAIL_JS_TEMPLATE_ARRANGE,
          e.target,
          process.env.GATSBY_EMAIL_JS_USER
        )
        .then(
          result => {
            circularRef.current.style.opacity = 0
            buttonTxtRef.current.style.opacity = 1
            setNameValue("")
            setPhoneNumberValue("")
            setEmailValue("")
            setFlightDate(moment(new Date()))
            setRadioValue("1-2 osób")
            handleClose()
            setEmailSendOpen(true)
            setSendError(false)
          },
          error => {
            circularRef.current.style.opacity = 0
            buttonTxtRef.current.style.opacity = 1
            setNameValue("")
            setPhoneNumberValue("")
            setEmailValue("")
            setFlightDate(moment(new Date()))
            setRadioValue("1-2 osób")
            handleClose()
            setEmailSendOpen(true)
            setSendError(true)
          }
        )
    } else return
  }
  return (
    <div style={{ padding: "20px" }}>
      <Typography
        variant="subtitle1"
        style={{ textAlign: "center", padding: "10px 20px" }}
      >
        {formatMessage({ id: "Enter your details" })}
      </Typography>
      <form onSubmit={handleSubmit}>
        <StyledGrid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <FormControl variant="outlined" style={{ margin: "10px" }}>
            <InputLabel
              htmlFor="name"
              style={{ color: "#fff", fontSize: "14px" }}
            >
              {formatMessage({ id: "Name and Surname" })}
            </InputLabel>
            <OutlinedInput
              error={errorTitle}
              id="name"
              variant="outlined"
              required={true}
              value={nameValue}
              onChange={handleNameChange}
              type="text"
              name="name"
              style={{
                width: "200px",
                backgroundColor: `${nameValue !== "" ? "#000" : null}`,
                color: `${nameValue !== "" ? "#fff" : null}`,
              }}
            />
          </FormControl>
          <FormControl variant="outlined" style={{ margin: "10px" }}>
            <InputLabel
              htmlFor="phone"
              style={{ color: "#fff", fontSize: "14px" }}
            >
              {formatMessage({ id: "Telephone" })}
            </InputLabel>
            <OutlinedInput
              error={errorTitle}
              id="phone"
              label="Telefon"
              variant="outlined"
              style={{
                width: "200px",

                backgroundColor: `${phoneNumberValue !== "" ? "#000" : null}`,
                color: `${phoneNumberValue !== "" ? "#fff" : null}`,
              }}
              required={true}
              value={phoneNumberValue}
              onChange={handlePhoneNumberChange}
              type="tel"
              name="phone"
            />
          </FormControl>
          <FormControl variant="outlined" style={{ margin: "10px" }}>
            <InputLabel
              htmlFor="mail"
              style={{ color: "#fff", fontSize: "14px" }}
            >
              Email
            </InputLabel>
            <OutlinedInput
              error={errorTitle}
              id="mail"
              label="Email"
              variant="outlined"
              style={{
                width: "200px",
                backgroundColor: `${emailValue !== "" ? "#000" : null}`,
                color: `${emailValue !== "" ? "#fff" : null}`,
              }}
              required={true}
              value={emailValue}
              onChange={handleEmailChange}
              type="email"
              name="email"
            />
          </FormControl>
          <FormControl variant="outlined" style={{ margin: "10px" }}>
            <InputLabel
              htmlFor="city"
              style={{ color: "#fff", fontSize: "14px" }}
            >
              {formatMessage({ id: "Flight location" })}
            </InputLabel>
            <OutlinedInput
              error={errorTitle}
              id="city"
              variant="outlined"
              style={{
                width: "200px",
                backgroundColor: `${cityValue !== "" ? "#000" : null}`,
                color: `${cityValue !== "" ? "#fff" : null}`,
              }}
              required={true}
              value={cityValue}
              onChange={handleCityChange}
              type="ship-city"
              name="city"
            />
          </FormControl>
          <FormControl style={{ margin: "10px" }}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ fontSize: "14px" }}
            >
              {formatMessage({ id: "Voivodeship" })}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={voivodeshipValue}
              onChange={handleVoivodeshipChange}
              style={{
                width: "200px",
                fontSize: "14px",
                paddingLeft: "10px",
                backgroundColor: `${voivodeshipValue !== "" ? "#000" : null}`,
                color: `${voivodeshipValue !== "" ? "#fff" : null}`,
              }}
              name="voivodeship"
            >
              <MenuItem value={"dolnośląskie"}>dolnośląskie</MenuItem>
              <MenuItem value={"kujawsko-pomorskie"}>
                kujawsko-pomorskie
              </MenuItem>
              <MenuItem value={"lubelskie"}>lubelskie</MenuItem>
              <MenuItem value={"lubuskie"}>lubuskie</MenuItem>
              <MenuItem value={"łódzkie"}>łódzkie</MenuItem>
              <MenuItem value={"małopolskie"}>małopolskie</MenuItem>
              <MenuItem value={"mazowieckie"}>mazowieckie</MenuItem>
              <MenuItem value={"opolskie"}>opolskie</MenuItem>
              <MenuItem value={"podkarpackie"}>podkarpackie</MenuItem>
              <MenuItem value={"podlaskie"}>podlaskie</MenuItem>
              <MenuItem value={"pomorskie"}>pomorskie</MenuItem>
              <MenuItem value={"śląskie"}>śląskie</MenuItem>
              <MenuItem value={"świętokrzyskie"}>świętokrzyskie</MenuItem>
              <MenuItem value={"warmińsko-mazurskie"}>
                warmińsko-mazurskie
              </MenuItem>
              <MenuItem value={"wielkopolskie"}>wielkopolskie</MenuItem>
              <MenuItem value={"zachodniopomorskie"}>
                zachodniopomorskie
              </MenuItem>
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="L"
              shouldDisableDate={disableWeekends}
              margin="normal"
              id="date-picker-inline"
              label={formatMessage({ id: "Flight date" })}
              value={flightDate}
              onChange={handleFlightDateChange}
              style={{
                margin: "10px",
                fontSize: "14px",
                width: "200px",
              }}
              name="date"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <FormControl style={{ margin: "20px 0 20px" }} component="fieldset">
            <FormLabel
              style={{
                color: "black",
                textAlign: "center",
                marginBottom: "10px",
                fontSize: "14px",
              }}
              component="p"
            >
              {formatMessage({ id: "Number of people" })}
            </FormLabel>
            <RadioGroup
              aria-label="amount"
              name="amount"
              row
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="1-2 osób"
                labelPlacement="bottom"
                control={<Radio />}
                label={`1-2 ${formatMessage({ id: "people" })}`}
              />
              <FormControlLabel
                value="3-5 osób"
                labelPlacement="bottom"
                control={<Radio />}
                label={`3-5 ${formatMessage({ id: "people" })}`}
              />
              <FormControlLabel
                value="powyżej 5 osób"
                labelPlacement="bottom"
                control={<Radio />}
                label={formatMessage({ id: "More" })}
              />
            </RadioGroup>
          </FormControl>
          <Divider
            light
            style={{ width: "100%", backgroundColor: "rgb(0 0 0 / 0.1)" }}
          />
          <Typography
            variant="subtitle1"
            style={{
              textAlign: "center",
              padding: "10px",
              fontSize: "12px",
            }}
          >
            {formatMessage({ id: "Your approximate price" })}:{" "}
            <span
              style={{
                fontWeight: "700",
                fontSize: "1.4rem",
                // color: "#cacc5b",
                color: "#000",
              }}
            >
              {radioValue === "1-2 osób" ? (
                `2200 ${formatMessage({ id: "PLN" })}`
              ) : (
                <>
                  {radioValue === "powyżej 5 osób"
                    ? `3200 ${formatMessage({ id: "PLN" })}`
                    : `2800 ${formatMessage({ id: "PLN" })}`}
                </>
              )}
              <Tooltip
                title={formatMessage({
                  id: "Personal information dialog information",
                })}
                arrow
              >
                <InfoIcon />
              </Tooltip>
            </span>
          </Typography>

          <Divider
            light
            style={{ width: "100%", backgroundColor: "rgb(0 0 0 / 0.1)" }}
          />

          <Grid container justify="space-between" style={{ width: "80%" }}>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              style={{ marginTop: "20px" }}
              onClick={onHandleClose}
            >
              {formatMessage({ id: "Cancel" })}
            </Button>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{
                marginTop: "20px",
                backgroundColor: `#0194da`,
              }}
            >
              <CircularProgress
                style={{
                  height: "24px",
                  width: "24px",
                  position: "absolute",
                  opacity: 0,
                }}
                ref={circularRef}
              />
              <span ref={buttonTxtRef} style={{ color: "white" }}>
                {formatMessage({ id: "Book" })}
              </span>
            </Button>
          </Grid>
        </StyledGrid>
      </form>
    </div>
  )
}

export default PersonalInformationForm
